import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const PageTitle = ({ className, children, ...props }) => (
  <h1 className={`${styles.pageTitle} ${className}`} {...props}>
    {children}
  </h1>
);

PageTitle.defaultProps = {
  className: '',
};

PageTitle.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default PageTitle;
