import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const PageBody = ({ className, ...props }) => (
  <div className={`${styles.pageBody} ${className}`} {...props} />
);

PageBody.defaultProps = {
  className: '',
};

PageBody.propTypes = {
  className: PropTypes.string,
};

export default PageBody;
