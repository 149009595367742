/* eslint no-use-before-define: 0 */

import React from 'react';
import ButtonArrowIcon from '../../../elements/Icons/ButtonArrowIcon';
import styles from './styles.module.css';

const navigateBack = () => window.history.back();

const BackLink = ({ text, children, ...props }) => (
  <span
    className={styles.backLink}
    role="button"
    onClick={navigateBack}
    onKeyPress={navigateBack}
    tabIndex={0}
    {...props}
  >
    <ButtonArrowIcon style={{ transform: 'rotateY(180deg)' }} />
    <span>
      {text}
      {children}
    </span>
  </span>
);

export default BackLink;
